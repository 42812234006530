<template>
  <div class="sidebar">
    <!-- ヘッダ -->
    <v-app-bar app color="primary" dark class="toolbar">
      <v-toolbar-title>
        <h3 class="ml-5 mr-5">日本冷蔵倉庫協会</h3>
      </v-toolbar-title>
      <v-toolbar-items>
        <!-- 調査回答 -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: 100px" v-bind="attrs" v-on="on">
              <h3 class="ml-5 mr-5">調査回答</h3>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="( attribute != 2 )" link :to="'/city_data_list'">
              <v-list-item-content>
                <v-list-item-title>月次報告データ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="( attribute == 0 || attribute == 2 )" link :to="'/factfinding_list'">
              <v-list-item-content>
                <v-list-item-title>実態調査</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="( attribute == 0 || attribute == 2 )" link :to="'/electricity_list'">
              <v-list-item-content>
                <v-list-item-title>電力調査</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="( attribute == 0 || attribute == 2 )" link :to="'/refrigerant_list'">
              <v-list-item-content>
                <v-list-item-title>冷媒調査</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- 調査管理 -->
        <v-btn v-if="( attribute == 1 || attribute == 2 )" icon style="width: 100px" link :to="'/survey_manage'">
          <h3 class="ml-5 mr-5">調査管理</h3>
        </v-btn>
        <!-- 会員管理 -->
        <v-menu v-if="( attribute == 1 || attribute == 2 )" offset-y :value="shown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: 100px" v-bind="attrs" v-on="on" v-on:click="openMenu()">
              <h3 class="ml-5 mr-5">会員管理</h3>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :to="'/member_list'">
              <v-list-item-content>
                <v-list-item-title>事業所会員一覧・登録・編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="( attribute == 2 )" link :to="'/company_list'">
              <v-list-item-content>
                <v-list-item-title>会社一覧・登録・編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link v-if="( attribute == 2 )" :to="'/association_list'">
              <v-list-item-content>
                <v-list-item-title>協会一覧・登録・編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="( attribute == 2 )" link :to="'/user_list'">
              <v-list-item-content>
                <v-list-item-title>ユーザ一覧・登録・編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="( attribute == 2 )" link :to="'/create_member_roster'">
              <v-list-item-content>
                <v-list-item-title>会員データ出力</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-menu v-if="( attribute == 2 )" offset-x transition="slide-x-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title icon v-bind="attrs" v-on="on" class="v-list-item v-list-item--link theme--light">協会費請求</v-list-item-title>
              </template>
                <v-list>
                <v-list-item link :to="'/request_association_fee/nichirei'" v-on:click="closeMenu()">
                  <v-list-item-content>
                    <v-list-item-title>日冷倉協</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="'/request_association_fee/reiji'" v-on:click="closeMenu()">
                  <v-list-item-content>
                    <v-list-item-title>冷事協会</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-menu>
          </v-list>
        </v-menu>
        <!-- メール管理 -->
        <v-menu v-if="( attribute == 2 )" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: 100px" v-bind="attrs" v-on="on">
              <h3 class="ml-5 mr-5">メール管理</h3>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :to="'/info_mail_send'">
              <v-list-item-content>
                <v-list-item-title>お知らせメール送信</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="'/mail_template_list'">
              <v-list-item-content>
                <v-list-item-title>メールテンプレート一覧・登録・編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="'/mail_send_history_list'">
              <v-list-item-content>
                <v-list-item-title>メール送信履歴</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="'/mail_send_group_list'">
              <v-list-item-content>
                <v-list-item-title>メール送信グループ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- マスタ管理 -->
        <v-menu v-if="( attribute == 2 )" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: 100px" v-bind="attrs" v-on="on">
              <h3 class="ml-5 mr-5">マスタ管理</h3>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="( attribute == 2 )" link :to="'/m_refrigerant_list'">
              <v-list-item-content>
                <v-list-item-title>冷媒マスタ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- 国交省登録情報 -->
        <v-btn v-if="( attribute == 2 )" icon style="width: 100px" link :to="'/mlit_data_list'">
          <h3 class="center ml-5 mr-5 ">国交省<br>登録情報</h3>
        </v-btn>

        <!-- <v-menu v-if="( attribute == 2 )" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: 100px" v-bind="attrs" v-on="on">
              <h3 class="ml-5 mr-5">国交省<br>登録情報</h3>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="( attribute == 2 )" link :to="'/mlit_data_list'">
              <v-list-item-content>
                <v-list-item-title>登録情報一覧</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>

        <v-select v-if="isCanvas"
            v-model="associationId"
            :items="associationList"
            item-text="association_name"
            item-value="association_id"
            class="mt-3"
            style="width: 200px;"
            @change="changeAssociation()"
        ></v-select>

        <!-- アカウントメニュー -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="width: auto; text-transform: none;" v-bind="attrs" v-on="on">
              <h2 class="ml-5 mr-5"><v-icon class="mr-2">mdi-account-circle</v-icon>{{username}}<v-icon large class="ml-2">mdi-triangle-small-down</v-icon></h2>
            </v-btn>
          </template>
          <v-list>

            <v-list-item v-if="( attribute >= 1 )" link :to="'/association_edit_mypage'">
              <v-list-item-content>
                <v-list-item-title>マイページ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- マイページの複数事業所対応 -->
            <div v-if="true" ><!----------------------------------------------->
            <v-list-item v-if="( attribute == 0 )" link :to="'/member_edit_mypage'">
              <v-list-item-content>
                <v-list-item-title>マイページ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div><!----------------------------------------------->
            <div v-else ><!----------------------------------------------->
            <v-menu v-if="( attribute == 0 )" offset-x transition="slide-x-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title icon v-bind="attrs" v-on="on" class="v-list-item v-list-item--link theme--light">マイページ</v-list-item-title>
              </template>
                <v-list v-for="mypage in mypageList" v-bind:key="mypage.memberNo">
                  <!-- <v-list-item link :to='"/member_edit_mypage"'  v-on:click="setMemberNo(mypage)"> -->
                  <v-list-item v-on:click="setMemberNo(mypage.memberNo)">
                  <v-list-item-content>
                    <v-list-item-title>{{ mypage.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            </div><!----------------------------------------------->


            <v-list-item v-on:click="logout()">
              <v-list-item-content>
                <v-list-item-title>ログアウト</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
<!--
        <v-btn style="width: 100px" icon>
          <h3 class="ml-5 mr-5">マイページ</h3>
        </v-btn>
        <v-btn style="width: 100px" icon v-on:click="logout()">
          <h3 class="ml-5 mr-5">Logout</h3>
        </v-btn>
-->
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import Association from "../model/m_association";

import Message from '../Lib/Message'
import Debug from "../Lib/Debug";

export default {
  components: {},

  name: "Sidebar",

  data: () => ({
    username: "",
    strAuthority: "",
    attribute: 0,
    shown: null,

    // menu
    offset: true,

    // canvasユーザのみ
    isCanvas: false,
    associationId: "",
    associationList: [],

    //
    mypageList: [],
  }),

  methods: {
    logout() {
      // ログアウト処理
      //this.$store.commit("setLoginStat", false);
      this.$store.commit('reset');  // stateを初期化

      // Cookie情報を削除
      this.$cookies.remove("userid");
      this.$cookies.remove("token");

      // ログイン画面に遷移
      this.$router.push("/login");
    },

    async loadData() {
      Debug.log("function[loadData]");
    },

    closeMstMenu() {
      Debug.log("closeMstMenu");
      Debug.log(this.$refs.mst_menu);
      this.$refs.mst_menu.isActive = false;
    },

    closeMenu() {
      this.shown = false;
    },

    setMemberNo(memberNo) {
      this.$store.commit('setMemberNo', memberNo);

      const to_path = 'member_edit_mypage';
      if (this.$route.path.indexOf() >= 0) {
        this.$router.push({path: to_path});
      } else {
        this.$router.push({ path: to_path, query: { member: memberNo } });
      }
    },

    openMenu() {
      this.shown = true;
    },

    async getAssociationList() {
      Debug.log("function[getAssociationList]");

      if (this.isCanvas) {
        // DBから協会情報取得
        Association.getDataList()
            .then(res => {
                if (res.data) {
                this.associationList = res.data.filter(aso => aso.type == '0');

                Debug.log(this.associationList);
                }
            })
            .catch(err => {
                Message.err(err, "協会を取得できませんでした");
            });
      }

    },

    changeAssociation() {
        Debug.log("function[changeAssociation]");

        if (this.isCanvas) {
          let association = this.$store.getters.getAssociation;
          let storeSearchCondition = this.$store.getters.getStoreSearchCondition;
          association.association_id = this.associationId;

          if (this.associationId == "000000") {
            storeSearchCondition.associationId = null;
            this.$store.commit('setAttribute', 2);
          } else {
            // ※associationId のみを設定（冷事協会は未設定）
            storeSearchCondition.associationId = this.associationId;
            this.$store.commit('setAttribute', 1);
          }
          this.$store.commit('setAssociation', association);
          this.$store.commit('setStoreSearchCondition', storeSearchCondition);

          this.$store.commit('setStoreSearchCondition', storeSearchCondition);

          Debug.log2("association: ", association);
          Debug.log2("storeSearchCondition: ", storeSearchCondition);

          // Topに戻る
          window.location.href = "/";
        }
    },

  },

  computed: {
  },

  created: function () {
    this.shown = null;
  },

  mounted: async function () {
    this.username = this.$store.getters.getUsername;
    this.attribute = this.$store.getters.getAttribute;

    // canvasユーザのみ
    this.isCanvas = (this.$store.getters.getUserid == "developer@canvas-works.jp");
    if (this.isCanvas) {
        this.associationId = this.$store.getters.getAssociation.association_id;
        Debug.log(this.associationId);
        await this.getAssociationList();
        Debug.log(this.associationList);
    }

    //
    this.mypageList = this.$store.getters.getMypageList;
  },

  watch: {},
};
</script>

<style></style>

<style scoped>
.toolbar {
  z-index: 100 !important;
  /* width: 1920px; */
}

.title_font {
  color: black;
}

.line-select {
  font-size: 15px;
}

/** メニュー分類 */
/* .menu-class {
} */
.sub-menu-class {
  width: 200px;
}
</style>
