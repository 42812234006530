var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('v-app-bar',{staticClass:"toolbar",attrs:{"app":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("日本冷蔵倉庫協会")])]),_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("調査回答")])])]}}])},[_c('v-list',[(( _vm.attribute != 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/city_data_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("月次報告データ")])],1)],1):_vm._e(),(( _vm.attribute == 0 || _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/factfinding_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("実態調査")])],1)],1):_vm._e(),(( _vm.attribute == 0 || _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/electricity_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("電力調査")])],1)],1):_vm._e(),(( _vm.attribute == 0 || _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/refrigerant_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("冷媒調査")])],1)],1):_vm._e()],1)],1),(( _vm.attribute == 1 || _vm.attribute == 2 ))?_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"icon":"","link":"","to":'/survey_manage'}},[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("調査管理")])]):_vm._e(),(( _vm.attribute == 1 || _vm.attribute == 2 ))?_c('v-menu',{attrs:{"offset-y":"","value":_vm.shown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100px"},attrs:{"icon":""},on:{"click":function($event){return _vm.openMenu()}}},'v-btn',attrs,false),on),[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("会員管理")])])]}}],null,false,1777466980)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'/member_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("事業所会員一覧・登録・編集")])],1)],1),(( _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/company_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("会社一覧・登録・編集")])],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/association_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("協会一覧・登録・編集")])],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/user_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ユーザ一覧・登録・編集")])],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/create_member_roster'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("会員データ出力")])],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"v-list-item v-list-item--link theme--light",attrs:{"icon":""}},'v-list-item-title',attrs,false),on),[_vm._v("協会費請求")])]}}],null,false,85983393)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'/request_association_fee/nichirei'},on:{"click":function($event){return _vm.closeMenu()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("日冷倉協")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":'/request_association_fee/reiji'},on:{"click":function($event){return _vm.closeMenu()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("冷事協会")])],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("メール管理")])])]}}],null,false,3850769965)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'/info_mail_send'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("お知らせメール送信")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":'/mail_template_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("メールテンプレート一覧・登録・編集")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":'/mail_send_history_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("メール送信履歴")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":'/mail_send_group_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("メール送信グループ")])],1)],1)],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('h3',{staticClass:"ml-5 mr-5"},[_vm._v("マスタ管理")])])]}}],null,false,3187314275)},[_c('v-list',[(( _vm.attribute == 2 ))?_c('v-list-item',{attrs:{"link":"","to":'/m_refrigerant_list'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("冷媒マスタ")])],1)],1):_vm._e()],1)],1):_vm._e(),(( _vm.attribute == 2 ))?_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"icon":"","link":"","to":'/mlit_data_list'}},[_c('h3',{staticClass:"center ml-5 mr-5 "},[_vm._v("国交省"),_c('br'),_vm._v("登録情報")])]):_vm._e()],1),_c('v-spacer'),_c('v-toolbar-items',[(_vm.isCanvas)?_c('v-select',{staticClass:"mt-3",staticStyle:{"width":"200px"},attrs:{"items":_vm.associationList,"item-text":"association_name","item-value":"association_id"},on:{"change":function($event){return _vm.changeAssociation()}},model:{value:(_vm.associationId),callback:function ($$v) {_vm.associationId=$$v},expression:"associationId"}}):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"auto","text-transform":"none"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('h2',{staticClass:"ml-5 mr-5"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-circle")]),_vm._v(_vm._s(_vm.username)),_c('v-icon',{staticClass:"ml-2",attrs:{"large":""}},[_vm._v("mdi-triangle-small-down")])],1)])]}}])},[_c('v-list',[(( _vm.attribute >= 1 ))?_c('v-list-item',{attrs:{"link":"","to":'/association_edit_mypage'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("マイページ")])],1)],1):_vm._e(),(true)?_c('div',[(( _vm.attribute == 0 ))?_c('v-list-item',{attrs:{"link":"","to":'/member_edit_mypage'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("マイページ")])],1)],1):_vm._e()],1):_c('div',[(( _vm.attribute == 0 ))?_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"v-list-item v-list-item--link theme--light",attrs:{"icon":""}},'v-list-item-title',attrs,false),on),[_vm._v("マイページ")])]}}],null,false,587352281)},_vm._l((_vm.mypageList),function(mypage){return _c('v-list',{key:mypage.memberNo},[_c('v-list-item',{on:{"click":function($event){return _vm.setMemberNo(mypage.memberNo)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(mypage.name))])],1)],1)],1)}),1):_vm._e()],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ログアウト")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }